.Compose-attachment-body {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  white-space: normal;
  height: 36px;
  word-break: break-word;
}

.Compose-attachment {
  @apply relative rounded-lg shadow overflow-hidden inline-block text-gray-medium flex-shrink-0 flex-grow-0 m-2;
  width: 155px;
  height: 105px;
}

.Compose-attachment.wider {
  width: 316px;
}

.Compose-attachment.wider .Compose-attachment-body {
  @apply px-16 text-center;
}

.Compose-attachment-overlay {
  @apply absolute inset-0 text-white rounded-lg;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
}
.Compose-attachment-header {
  min-height: 32px;
}

.Compose-attachment:hover .Compose-attachment-overlay {
  @apply flex;
}

.Compose-attachment-clear {
  visibility: hidden;
}

.Compose-attachment:hover .Compose-attachment-clear {
  visibility: initial;
}
.Compose-attachment-checkbox {
  display: none !important;
}
.Compose-attachment:hover .Compose-attachment-checkbox {
  display: block !important;
}
.Compose-attachment.Compose-attachment-selected .Compose-attachment-checkbox {
  display: block !important;
}
