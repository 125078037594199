@import 'theme.css';
@import 'base.css';
@import 'whitelist.css';
@import 'animation.css';

@tailwind components;

html {
  @apply bg-app;
}

.bottom-full {
  bottom: 100%;
}
.top-full {
  top: 100%;
}
.left-full {
  left: 100%;
}
.right-full {
  right: 100%;
}

.skeleton {
  animation: fade 1.5s ease-in-out infinite;
}

.hl {
  @apply text-black bg-highlight px-1 rounded;
}

@keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.gradient {
  --color-gradient-rgb: 255, 255, 255;
  --color-bg-gradient-0: rgba(var(--color-gradient-rgb), 0);
  --color-bg-gradient-50: rgba(var(--color-gradient-rgb), 0.8);
  --color-bg-gradient-100: rgba(var(--color-gradient-rgb), 1);
}

.dark .gradient {
  --color-gradient-rgb: 18, 18, 18;
}

.dark .secondary-gradient {
  --color-gradient-rgb: 43, 43, 43;
}

.gradient.bg-gradient-r {
  background-color: var(--color-bg-primary);
  background: linear-gradient(
    90deg,
    var(--color-bg-gradient-0) 0%,
    var(--color-bg-gradient-50) 50%,
    var(--color-bg-gradient-100) 100%
  );
}

.gradient.bg-gradient-l {
  background-color: var(--color-bg-primary);
  background: linear-gradient(
    90deg,
    var(--color-bg-gradient-100) 0%,
    var(--color-bg-gradient-50) 50%,
    var(--color-bg-gradient-0) 100%
  );
}

.secondary-gradient.bg-gradient-b,
.gradient.bg-gradient-b {
  background-color: var(--color-bg-primary);
  background: linear-gradient(
    0,
    var(--color-bg-gradient-100) 0%,
    var(--color-bg-gradient-50) 50%,
    var(--color-bg-gradient-0) 100%
  );
}

.transform-ease-out {
  transition: transform 200ms ease-out;
}
.left-ease-out {
  transition: left 200ms ease-out;
}

.cursor-grabbing {
  cursor: grabbing;
  cursor: -webkit-grabbing;
}

.gray-filter {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.all-ease-in-out {
  @apply transition-all duration-300 ease-in-out;
}

@tailwind utilities;
