.disable-scrollbars {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.disable-scrollbars::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.recommendation-contact-avatar {
  @apply w-12 h-12;
}

.recommendation-contact-avatar > img {
  @apply border rounded-full;
}

.recommendation-photo-thumbnail {
  @apply flex-grow-0 flex-shrink-0;
}
.recommendation-photo-thumbnail img {
  @apply w-full h-28 object-cover;
}
