.suggestedDetail {
  animation: widthGradient 1s;
}

@keyframes widthGradient {
  0% {
    transform: scale(1, 1);
    opacity: 1;
  }
  50% {
    transform: scale(1.03, 1);
    opacity: 0.9;
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
