/* purgecss start ignore */
.__PrivateStripeElement {
  @apply h-full;
}

.__PrivateStripeElement iframe {
  height: 100% !important;
}

[data-theme='dark'] iframe {
  color-scheme: auto !important;
}
/* purgecss end ignore */
