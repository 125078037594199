@tailwind base;

@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap');

html {
  @apply text-medium;
  @apply text-base;
  @apply font-sans;
}

@media (min-width: 768px) {
  #root {
    @apply h-screen;
  }
}

*,
*:focus {
  outline: 0 !important;
  -webkit-tap-highlight-color: transparent;
}
