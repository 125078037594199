/*! purgecss start ignore */
.scrollbar-hidden {
  scrollbar-width: none;
}
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

/*
 * Referred from https://css-tricks.com/snippets/css/change-autocomplete-styles-webkit-browsers/
 *
 * Change Autocomplete styles in Chrome
 *
*/
.dark :-webkit-autofill,
.dark :-webkit-autofill:hover,
.dark :-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
}
/*! purgecss end ignore */
