/*! purgecss start ignore */
:root {
  --animation-duration: 300ms;
}
.thread-list > .ReactVirtualized__Grid__innerScrollContainer {
  transition: height var(--animation-duration) ease-out,
    max-height var(--animation-duration) ease-out;
}
.thread-list-item {
  transition: all var(--animation-duration) ease-out;
}
.thread-list-item.removed {
  opacity: 0.2;
}

.card-list-item {
  transition: all var(--animation-duration) ease-out;
}

.card-list-item.removed {
  opacity: 0.2;
}
.card-list > .ReactVirtualized__Grid__innerScrollContainer {
  transition: width var(--animation-duration) ease-out,
    max-width var(--animation-duration) ease-out;
}

.fade-enter {
  opacity: 0;
}
.fade-exit {
  opacity: 1;
}
.fade-enter-active {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
}
.fade-enter-active,
.fade-exit-active {
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
/*! purgecss end ignore */
