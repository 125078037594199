.Compose-clear {
  @apply hidden absolute right-0 top-0 mr-3 mt-2 cursor-pointer;
}

.Compose-popover {
  @apply text-sm relative shadow-lg rounded bg-card;
  width: 250px;
}

.Compose-popover:hover .Compose-clear {
  @apply block;
}

.Compose-formatting > * + * {
  @apply ml-3;
}
