/* purgecss start ignore */
.iti--container {
  z-index: 10000;
}
.iti--allow-dropdown {
  padding: 0 8px;
  background-color: var(--color-bg-secondary);
  width: 100%;
}

.iti__country-list {
  background-color: var(--color-bg-card);
  border: none;
  @apply shadow-lg;
}
.dark .iti__country-list {
  @apply shadow-deep;
}
.iti__country.iti__highlight {
  background-color: var(--color-bg-secondary);
}
.iti__divider {
  border-bottom-color: var(--color-divider);
}

/* purgecss end ignore */
