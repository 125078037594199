@media screen and (max-width: 375px) {
  .footer {
    @apply text-xs;
  }
}

@media screen and (min-width: 375px) and (max-width: 414px) {
  .footer {
    @apply text-sm;
  }
}

@media screen and (max-width: 414px) {
  .brand-icon {
    @apply h-20;
  }

  .brand-logo {
    @apply h-10;
  }

  .section {
    @apply mb-8;
  }

  .content {
    @apply text-lg;
  }

  .download-item {
    @apply w-32;
  }
}
