/* purgecss start ignore */

.tox-tinymce {
  border: none !important;
}

.mce-content-body {
  font: small/ 1.5 Arial, Helvetica, sans-serif;
  color: #000000;

  margin: 0;
}

.ephox-snooker-resizer-bar {
  background-color: #b4d7ff;
  opacity: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ephox-snooker-resizer-cols {
  cursor: col-resize;
}
.ephox-snooker-resizer-rows {
  cursor: row-resize;
}
.ephox-snooker-resizer-bar.ephox-snooker-resizer-bar-dragging {
  opacity: 1;
}
.mce-content-body img::-moz-selection {
  background: none;
}
.mce-content-body img::selection {
  background: none;
}
.mce-content-body img[data-mce-selected] {
  outline: 3px solid #b4d7ff;
}
.mce-content-body div.mce-resizehandle {
  background-color: #4099ff;
  border-color: #4099ff;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  height: 10px;
  position: absolute;
  width: 10px;
  z-index: 10000;
}
.mce-content-body div.mce-resizehandle:hover {
  background-color: #4099ff;
}
.mce-content-body div.mce-resizehandle:nth-of-type(1) {
  cursor: nwse-resize;
}
.mce-content-body div.mce-resizehandle:nth-of-type(2) {
  cursor: nesw-resize;
}
.mce-content-body div.mce-resizehandle:nth-of-type(3) {
  cursor: nwse-resize;
}
.mce-content-body div.mce-resizehandle:nth-of-type(4) {
  cursor: nesw-resize;
}
.mce-content-body .mce-resize-backdrop {
  z-index: 10000;
}
.mce-content-body .mce-clonedresizable {
  cursor: default;
  opacity: 0.5;
  outline: 1px dashed black;
  position: absolute;
  z-index: 10001;
}
.mce-content-body .mce-resize-helper {
  background: #555;
  background: rgba(0, 0, 0, 0.75);
  border: 1px;
  border-radius: 3px;
  color: white;
  display: none;
  font-family: sans-serif;
  font-size: 12px;
  line-height: 14px;
  margin: 5px 10px;
  padding: 5px;
  position: absolute;
  white-space: nowrap;
  z-index: 10002;
}

.mce-content-body a,
a:active,
a:visited {
  color: #136aee;
}

.mce-content-body[data-theme='dark'] a,
a:active,
a:visited {
  color: #2d7ef7;
}

/* stylelint-enable */
.mce-content-body {
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.mce-content-body .mce-visual-caret {
  background-color: black;
  background-color: currentColor;
  position: absolute;
}
.mce-content-body .mce-visual-caret-hidden {
  display: none;
}
.mce-content-body *[data-mce-caret] {
  left: -1000px;
  margin: 0;
  padding: 0;
  position: absolute;
  right: auto;
  top: 0;
}
.mce-content-body .mce-offscreen-selection {
  left: -2000000px;
  max-width: 1000000px;
  position: absolute;
}
.mce-content-body *[contentEditable='false'] {
  cursor: default;
}
.mce-content-body *[contentEditable='true'] {
  cursor: text;
}

/* Link Toolbar */
.tox .tox-toolbar {
  border-radius: 0.25rem;
}

.tox .tox-tbtn {
  height: 8px;
  padding: 8px;
  background: #fff;
}

.tox .tox-tbtn:focus,
.tox .tox-tbtn:hover,
.tox .tox-tbtn:active {
  background: #fff;
}

.tox .tox-tbtn > span {
  color: #136aee;
  padding: 0 8px;
}

.tox .tox-tbtn > span:hover {
  text-decoration: underline;
  cursor: pointer;
}

.spell-error {
  position: absolute;
  box-sizing: content-box;
  display: inline;
  min-width: 0.5em;
  opacity: 0.85;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0IiBoZWlnaHQ9IjUiPgogIDxkZWZzPgogICAgICA8cGF0dGVybiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGlkPSJzcGVsbGluZy16aWd6YWciIHdpZHRoPSI0IiBoZWlnaHQ9IjUiIHBhdHRlcm5Vbml0cz0ib2JqZWN0Qm91bmRpbmdCb3giIHBhdHRlcm5Db250ZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIiB4PSIwIiB5PSIwIj4KICAgICAgICA8cGF0aCBkPSJNIDAgMyBMIDIgMSBMIDQgMyIgc3Ryb2tlPSIjRUE0MzM1IiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz4KICAgICAgPC9wYXR0ZXJuPgogICAgPC9kZWZzPgogIDxyZWN0IHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZmlsbD0idXJsKCNzcGVsbGluZy16aWd6YWcpIiBoZWlnaHQ9IjEwMCUiIHdpZHRoPSIxMDAlIiAvPgo8L3N2Zz4=);
  background-position: -1px calc(100% + 3px);
  background-repeat: repeat-x;
  border-bottom: 2px solid transparent;
  outline: none;
  transition: background-color 0.4s ease;
}

.grammar-error {
  position: absolute;
  box-sizing: content-box;
  display: inline;
  min-width: 0.5em;
  opacity: 0.85;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0IiBoZWlnaHQ9IjUiPgogIDxkZWZzPgogICAgICA8cGF0dGVybiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGlkPSJzcGVsbGluZy16aWd6YWciIHdpZHRoPSI0IiBoZWlnaHQ9IjUiIHBhdHRlcm5Vbml0cz0ib2JqZWN0Qm91bmRpbmdCb3giIHBhdHRlcm5Db250ZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIiB4PSIwIiB5PSIwIj4KICAgICAgICA8cGF0aCBkPSJNIDAgMyBMIDIgMSBMIDQgMyIgc3Ryb2tlPSIjNDI4NUY0IiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz4KICAgICAgPC9wYXR0ZXJuPgogICAgPC9kZWZzPgogIDxyZWN0IHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZmlsbD0idXJsKCNzcGVsbGluZy16aWd6YWcpIiBoZWlnaHQ9IjEwMCUiIHdpZHRoPSIxMDAlIiAvPgo8L3N2Zz4=);
  background-position: -1px calc(100% + 3px);
  background-repeat: repeat-x;
  border-bottom: 2px solid transparent;
  outline: none;
  transition: background-color 0.4s ease;
}

#autosuggest-tag {
  color: #666666;
  white-space: pre;
}

#autosuggest-tag[data-theme='dark'] {
  color: #999999;
}

#spellcheck-button {
  display: block;
  padding: 0.5rem;
  min-width: 80px;
  width: 100%;

  font-size: 0.85rem;
  line-height: 1rem;
  text-align: center;
}

#spellcheck-button:hover {
}

#spellcheck-button-container {
  border-radius: 0.25rem;
  padding: 0.5rem 0;
  box-shadow: rgb(0 0 0 / 10%) 0px 3px 6px;
}

/* purgecss end ignore */
