.image-stage {
  @apply bg-app;
  @apply flex flex-col items-center justify-center;
  @apply absolute;
  @apply p-8;
  will-change: transform, opacity;
}

.dark .image-stage {
  /* Adapt to the onboarding promotion video background */
  background-color: #101010;
}

.image-stage img {
  height: 335px;
}
