.Contact-form input {
  @apply rounded-none !important;
  @apply border-b px-1 py-2 bg-transparent text-sm;
}

.Contact-form input::placeholder {
  @apply text-gray-light;
}
.Contact-form-mask {
  color: var(--color-bg-card);
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  bottom: 0;
}

[data-theme='dark'] .Contact-form-mask {
  background: linear-gradient(to bottom, transparent, rgba(37, 37, 37, 1) 100%);
}
