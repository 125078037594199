.sift-modal-image {
  @apply w-1/5 h-screen hidden items-center justify-center;
}

@screen md {
  .sift-modal-image {
    @apply flex;
  }
}

.sift-modal-image img {
  @apply object-contain;
  height: 200px;
  width: 200px;
}
