.advanced-filter table {
  @apply border-separate text-dark;
  border-spacing: 0 1rem;
}

.advanced-filter table td.key {
  @apply w-1/5 font-semibold;
}

.advanced-filter table td.value input {
  @apply border border-gray-light px-4 py-2 rounded-none bg-transparent;
}

.overlay-gradient {
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 30%,
    rgba(255, 255, 255, 0) 100%
  );
}
