.btn {
  @apply outline-none font-bold;
}

.btn:focus {
  @apply outline-none;
}

.btn:not([disabled]):hover {
  @apply shadow;
}

.btn:disabled,
.btn.plain.disabled {
  @apply bg-gray-divider text-light;
}

.btn:not([disabled]).outline:hover {
  @apply shadow-none;
}
