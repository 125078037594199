/* purgecss start ignore */
.attachment-box-item-enter {
  opacity: 0;
}
.attachment-box-item-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.attachment-box-item-exit {
  opacity: 1;
}
.attachment-box-item-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

.icon-spin {
  animation: fa-spin 2s infinite linear;
}
@keyframes fa-spin {
  0% {
    transform: rotate(359deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
/* purgecss end ignore */
