/*
 * From https://github.com/haubek/bootstrap4c-custom-switch/blob/master/dist/css/component-custom-switch.css
 **/

.custom-switch {
  @apply leading-none;
}
.custom-switch .custom-switch-input {
  z-index: -1;
  @apply absolute opacity-0;
}
.custom-switch .custom-switch-input,
.custom-switch .custom-switch-input:after,
.custom-switch .custom-switch-input:before,
.custom-switch .custom-switch-input *,
.custom-switch .custom-switch-input *:after,
.custom-switch .custom-switch-input *:before,
.custom-switch .custom-switch-input + .custom-switch-btn {
  box-sizing: border-box;
}
.custom-switch .custom-switch-input:selection,
.custom-switch .custom-switch-input:after:selection,
.custom-switch .custom-switch-input:before:selection,
.custom-switch .custom-switch-input *:selection,
.custom-switch .custom-switch-input *:after:selection,
.custom-switch .custom-switch-input *:before:selection,
.custom-switch .custom-switch-input + .custom-switch-btn:selection {
  @apply hidden;
}
.custom-switch .custom-switch-input + .custom-switch-btn {
  width: 68px;
  height: 38px;
  padding: 4px;
  transition: all 150ms ease;
  @apply bg-gray-ultra-light select-none cursor-pointer rounded-full m-0 outline-none inline-block relative;
}
.custom-switch .custom-switch-input + .custom-switch-btn:after,
.custom-switch .custom-switch-input + .custom-switch-btn:before {
  content: '';
  width: 30px;
  height: 30px;
  @apply relative block;
}
.custom-switch .custom-switch-input + .custom-switch-btn:after {
  transition: all 150ms ease;
  @apply left-0 rounded-full bg-white;
}
.custom-switch .custom-switch-input + .custom-switch-btn:before {
  @apply hidden;
}
.custom-switch .custom-switch-input + .custom-switch-btn.text-hide {
  top: -0.8rem;
}
.custom-switch .custom-switch-input:checked + .custom-switch-btn {
  @apply bg-primary-active;
}
.custom-switch .custom-switch-input:checked + .custom-switch-btn:after {
  left: 30px;
}
.custom-switch
  .custom-switch-input:checked
  + .custom-switch-btn
  ~ .custom-switch-content-checked {
  @apply opacity-100 h-auto;
}
.custom-switch
  .custom-switch-input:checked
  + .custom-switch-btn
  ~ .custom-switch-content-unchecked {
  @apply hidden opacity-0 h-0;
}
.custom-switch
  .custom-switch-input:not(:checked)
  + .custom-switch-btn
  ~ .custom-switch-content-checked {
  @apply hidden opacity-0 h-0;
}
.custom-switch
  .custom-switch-input:not(:checked)
  + .custom-switch-btn
  ~ .custom-switch-content-unchecked {
  @apply opacity-100 h-auto;
}
.custom-switch .custom-switch-input[disabled] + .custom-switch-btn {
  @apply opacity-50 cursor-default;
}

.custom-switch-xs .custom-switch-input + .custom-switch-btn {
  width: 46px;
  height: 26px;
  padding: 3px;
}
.custom-switch-xs .custom-switch-input + .custom-switch-btn:after,
.custom-switch-xs .custom-switch-input + .custom-switch-btn:before {
  width: 20px;
  height: 20px;
}
.custom-switch-xs .custom-switch-input:checked + .custom-switch-btn:after {
  left: 20px;
}

.custom-switch-sm .custom-switch-input + .custom-switch-btn {
  width: 60px;
  height: 31px;
  padding: 3px;
}
.custom-switch-sm .custom-switch-input + .custom-switch-btn:after,
.custom-switch-sm .custom-switch-input + .custom-switch-btn:before {
  width: 25px;
  height: 25px;
}
.custom-switch-sm .custom-switch-input:checked + .custom-switch-btn:after {
  left: 29px;
}

.custom-switch-lg .custom-switch-input + .custom-switch-btn {
  width: 76px;
  height: 42px;
  padding: 4px;
  border-radius: 82px;
}
.custom-switch-lg .custom-switch-input + .custom-switch-btn:after,
.custom-switch-lg .custom-switch-input + .custom-switch-btn:before {
  width: 34px;
  height: 34px;
}
.custom-switch-lg .custom-switch-input:checked + .custom-switch-btn:after {
  left: 34px;
}
