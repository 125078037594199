:root {
  /* Palette */
  /* Light */
  --color-light-primary: #0265f2;
  --color-light-primary-active: #1450df;
  --color-light-green: #31d76e;
  --color-light-red: #ff485f;
  --color-light-yellow: #ffd41a;
  --color-light-orange: #ffa115;
  --color-light-purple: #755cf2;
  --color-light-teal: #59dce4;
  --color-light-white: #ffffff;
  --color-light-black: #000000;

  /* Dark */
  --color-dark-primary: #2d7ef7;
  --color-dark-primary-active: #136aee;
  --color-dark-green: #51dd84;
  --color-dark-red: #fd6478;
  --color-dark-yellow: #ffdb3d;
  --color-dark-orange: #feaf39;
  --color-dark-purple: #9582f5;
  --color-dark-teal: #7fe4ea;
  --color-dark-white: #fff;
  --color-dark-black: #000;

  /* Text-light */
  /* Light */
  --color-light-text-ultra-light: #cecece;
  --color-light-text-light: #999999;
  --color-light-text-medium: #666666;
  --color-light-text-dark: #000001;

  /* Dark */
  --color-dark-text-ultra-light: #666666;
  --color-dark-text-light: #999999;
  --color-dark-text-medium: #cecece;
  --color-dark-text-dark: #f2f2f2;

  /* Back-lightground */
  /* Light */
  --color-light-bg-primary: #fffffe;
  --color-light-bg-secondary: #f6f9fc;
  --color-light-bg-card: #fffefe;
  --color-light-bg-error: #fdf1f4;
  --color-light-bg-highlight: #dbe9fe;
  --color-light-bg-ultra-light: #fafafa;

  /* pane */
  --color-light-bg-pane-primary: #fff2f4;
  --color-dark-bg-pane-primary: #2f2123;

  /* Dark */
  --color-dark-bg-primary: #121212;
  --color-dark-bg-secondary: #2b2b2b;
  --color-dark-bg-card: #252525;
  --color-dark-bg-error: #412528;
  --color-dark-bg-highlight: #dbe9fe;
  --color-dark-bg-ultra-light: #1b1b1b;

  /* Divi-lightder */
  /* Light */
  --color-light-divider: #ebebeb;
  /* Dark */
  --color-dark-divider: #323233;

  /* Shadows */
  /* Light */
  --shadow-light-shallow: rgb(0 0 0 / 10%) 0px 3px 6px;
  --shadow-light-deep: rgb(0 0 0 / 20%) 0px 8px 10px;
  --shadow-light-deep-l: rgb(0 0 0 / 20%) -5px 0px 8px;
  --shadow-light-deep-r: rgb(0 0 0 / 20%) 5px 0px 8px;
  --shadow-light-deep-t: rgb(0 0 0 / 20%) 0px -2px 5px;

  /* Dark */
  --shadow-dark-shallow: rgb(0 0 0 / 30%) 0px 4px 4px;
  --shadow-dark-deep: rgb(0 0 0 / 40%) 0px 8px 10px;
  --shadow-dark-deep-l: rgb(0 0 0 / 40%) -5px 0px 16px;
  --shadow-dark-deep-r: rgb(0 0 0 / 40%) 5px 0px 16px;
  --shadow-dark-deep-t: rgb(0 0 0 / 40%) 0px -2px 5px;

  --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --shadow-lg-t: 0 -10px 15px -3px rgba(0, 0, 0, 0.1),
    0 -4px 6px -2px rgba(0, 0, 0, 0.05);
}

:root,
div[data-theme='light'] {
  /* Palette */
  --color-primary: var(--color-light-primary);
  --color-primary-active: var(--color-light-primary-active);
  --color-green: var(--color-light-green);
  --color-red: var(--color-light-red);
  --color-yellow: var(--color-light-yellow);
  --color-orange: var(--color-light-orange);
  --color-purple: var(--color-light-purple);
  --color-teal: var(--color-light-teal);
  --color-white: var(--color-light-white);
  --color-black: var(--color-light-black);

  /* Text */
  --color-text-ultra-light: var(--color-light-text-ultra-light);
  --color-text-light: var(--color-light-text-light);
  --color-text-medium: var(--color-light-text-medium);
  --color-text-dark: var(--color-light-text-dark);

  /* Background */
  --color-bg-primary: var(--color-light-bg-primary);
  --color-bg-secondary: var(--color-light-bg-secondary);
  --color-bg-card: var(--color-light-bg-card);
  --color-bg-error: var(--color-light-bg-error);
  --color-bg-highlight: var(--color-light-bg-highlight);
  --color-bg-ultra-light: var(--color-light-bg-ultra-light);

  /* pane */
  --color-bg-pane-primary: var(--color-light-bg-pane-primary);

  /* Divider */
  --color-divider: var(--color-light-divider);

  /* Shadows */
  --shadow-shallow: var(--shadow-light-shallow);
  --shadow-deep: var(--shadow-light-deep);
  --shadow-deep-l: var(--shadow-light-deep-l);
  --shadow-deep-r: var(--shadow-light-deep-r);
  --shadow-deep-t: var(--shadow-light-deep-t);
}

:root[data-theme='dark'],
div[data-theme='dark'] {
  /* Palette */
  --color-primary: var(--color-dark-primary);
  --color-primary-active: var(--color-dark-primary-active);
  --color-green: var(--color-dark-green);
  --color-red: var(--color-dark-red);
  --color-yellow: var(--color-dark-yellow);
  --color-orange: var(--color-dark-orange);
  --color-purple: var(--color-dark-purple);
  --color-teal: var(--color-dark-teal);
  --color-white: var(--color-dark-white);
  --color-black: var(--color-dark-black);

  /* Text */
  --color-text-ultra-light: var(--color-dark-text-ultra-light);
  --color-text-light: var(--color-dark-text-light);
  --color-text-medium: var(--color-dark-text-medium);
  --color-text-dark: var(--color-dark-text-dark);

  /* Background */
  --color-bg-primary: var(--color-dark-bg-primary);
  --color-bg-secondary: var(--color-dark-bg-secondary);
  --color-bg-card: var(--color-dark-bg-card);
  --color-bg-error: var(--color-dark-bg-error);
  --color-bg-highlight: var(--color-dark-bg-highlight);
  --color-bg-ultra-light: var(--color-dark-bg-ultra-light);

  /* pane */
  --color-bg-pane-primary: var(--color-dark-bg-pane-primary);

  /* Divider */
  --color-divider: var(--color-dark-divider);

  /* Shadows */
  --shadow-shallow: var(--shadow-dark-shallow);
  --shadow-deep: var(--shadow-dark-deep);
  --shadow-deep-l: var(--shadow-dark-deep-l);
  --shadow-deep-r: var(--shadow-dark-deep-r);
  --shadow-deep-t: var(--shadow-dark-deep-t);
}

[data-theme='light'] {
  color-scheme: light;
}

[data-theme='dark'] {
  color-scheme: dark;
}
