.Overlay-mask {
  @apply absolute inset-0 bg-black opacity-25 hidden z-10;
}
.Overlay-actions {
  @apply absolute inset-0 text-white hidden z-20;
}

.Overlay-container:hover .Overlay-mask,
.Overlay-container:hover .Overlay-actions {
  @apply block;
}
